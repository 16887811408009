import React from 'react'
import PropTypes from 'prop-types'
import StarRatingComponent from 'react-star-rating-component';


const Star = ({ rating }) => (
  <StarRatingComponent
    name="rate"
    starCount={5}
    value={rating}
    editing={false}
  />
)

export default Star

Star.propTypes = {
  rating: PropTypes.number,
}
