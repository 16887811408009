import React, { useState } from 'react'
import { Box, Card, Flex, Button, Text, Heading, Img } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'
import columnSizeMatcher from '@components/utils/columnSizeMatcher'
import Body from './Card.Body'
import Star from '../Star'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

const styles = {
  card: {
    overflow: `hidden`,
    height: `full`
  },
  cardVertical: {},
  content: {
    alignItems: `stretch`,
    height: `full`,
  }
}

const CardCoupon = ({ columns, onMouseOver, ...props }) => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => {
    setOpen(true)
    window.open(props.url, '_blank')
  }
  const onCloseModal = () => setOpen(false)

  return (
    <Box
      className="blog_card"
      sx={columnSizeMatcher(columns)}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
    >
      <Card
        variant="interactive"
        sx={{
          ...styles.card,
          variant: rv(props.variant, 'card')
        }}
      >
        <Flex
          as="article"
          sx={{
            ...styles.content,
            variant: rv(props.variant, 'content'),
            '@media screen and (max-width: 1023px)': {
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '2rem',
            },
          }}
        >
          <Body {...props} isCoupon={true}>
            <Flex sx={{
              fontSize: '14px',
              flexDirection: props.variant[1] === 'cards.vertical' ? 'column' : 'row',
              '@media screen and (max-width: 640px)': {
                justifyContent: 'space-between'
              },
              '@media screen and (max-width: 370px)': {
                fontSize: '12px'
              },
            }}>
              <Box sx={{
                marginRight: '15px',
                display: 'flex',
                '@media screen and (max-width: 1023px) and (min-width: 640px)': {
                  fontSize: '12px'
                },
              }}>
                <Text sx={{ fontWeight: 'bold' }}>Expiration date:</Text>
                <Text sx={{ marginLeft: '6px' }}>{props.expirationDate}</Text>
              </Box>
              <Box sx={{ fontWeight: 'bold', color: 'red' }}>
                Only {props.onlyLeft} left
              </Box>
            </Flex>
          </Body>
          <Box sx={{
            flex: '2',
            justifyContent: 'center',
            display: 'flex',
            alignItems: props.variant[1] === 'cards.vertical' ? 'center' : 'flex-start',
            flexDirection: 'column'
          }}>
            <Box sx={{
              '@media screen and (max-width: 1023px)': {
                fontSize: '16px',
                width: '100%',
                textAlign: 'center'
              },
            }}>
              Rating
            </Box>
            <Flex sx={{
              alignItems: 'center',
              fontSize: '23px',
              '@media screen and (max-width: 640px)': {
                fontSize: '20px'
              },
            }}>
              <Text>{props.rating}</Text>
              <Star rating={props.rating} />
            </Flex>
            <Button sx={{
              borderRadius: '0',
              border: '3px dashed #667eea',
              textTransform: 'uppercase',
              '&:hover': {
                background: 'none',
                color: '#2e3648',
              },
              mb: props.variant[1] === 'cards.vertical' ? '3' : '0'
            }} onClick={onOpenModal}>
              Get coupon
            </Button>
            <Modal open={open} onClose={onCloseModal} center>
              <Flex sx={{ alignItems: 'center', fontSize: '14px' }}>
                <Text>Rating: {props.rating}</Text>
                <Star rating={props.rating} />
              </Flex>
              <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: '3' }}>
                <Heading sx={{
                  border: '3px dashed',
                  padding: '2px 12px',
                  fontSize: '23px'
                }} as="h3">{props.code}</Heading>
                {props.logo && <Img image={props.logo} alt={props.title} />}
                <Text>{props.title}</Text>
                <Text>{props.couponText}</Text>
                <Text>{props.text}</Text>
                <Flex sx={{ fontSize: '23px', fontWeight: 'bold' }}>Your Discount is <Text sx={{
                  color: 'red',
                  marginLeft: '6px'
                }}>activated!</Text></Flex>
              </Flex>
              <Flex sx={{ fontSize: '14px', justifyContent: 'center' }}>
                <Box sx={{ marginRight: '15px', display: 'flex' }}>
                  <Text sx={{ fontWeight: 'bold' }}>Expiration date:</Text>
                  <Text sx={{ marginLeft: '6px' }}>{props.expirationDate}</Text>
                </Box>
                <Box sx={{ fontWeight: 'bold', color: 'red' }}>
                  Only {props.onlyLeft} left
                </Box>
              </Flex>
            </Modal>
          </Box>
        </Flex>
      </Card>
    </Box>
  )

}

export default CardCoupon
