import React from 'react'
import { Box, Heading, Text } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'
import CardBodyCategory from './Card.Body.Category'
import CardBodyTitle from './Card.Body.Title'
import CardBodyExcerpt from './Card.Body.Excerpt'

const styles = {
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: '4'
  }
}

const CardBody = ({ children, omitBody, isCoupon, ...props }) => {

  if(isCoupon) {
    props.category = {
      name: 'Coupons',
      slug: '/crypto/coupons'
    }
  }

  return !omitBody && (
    <Box
      sx={{
        ...styles.body,
        variant: rv(props.variant, 'body')
      }}
    >
      <CardBodyCategory {...props} />
      {isCoupon
        ?
        <>
            <Heading as="h3">{props.title}</Heading>
            <Text sx={{fontSize: "14px", mb: 3}}>{props.text}</Text>
        </>
        :
        <>
          <CardBodyTitle {...props} />
          <CardBodyExcerpt {...props} />
        </>
      }
      {children}
    </Box>
  )
}

export default CardBody
